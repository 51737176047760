import React from "react";
import { Avatar } from "antd";
import { Tabs, Radio } from "antd";
import { useTranslation } from "react-i18next";
import PersonInformation from "./PersonData";
import EmployerForm from "./../../EmployerInformation/EmployerFrom";
import JobHistory from "./../../JobHistoryComponent/JobHistory";
import Qualification from "./../../QualificaionComponent/QualificationForm";
const { TabPane } = Tabs;

const ClientProfileTabList = ({
  onGetClientProfile,
  clientProfile,
  onGetCustomFeild,
  customFeild,
  onaddClientProfile,

  onUpdateClientEmail,

  onClientProfileSaveAddress,

  onUpdateClientPhone,

  onUpdateClientPassport,

  onUpdateClientMedical,

  onGetEmployerInformation,
  employerInformationRes,
  onAddemployerInformation,
  onUpdateEmployerInformation,

  onaddJobHistory,

  onAddQualificaion,

  onGetJobHistoryData,
  getJobHistoryRes,

  onPutJobHistoryData,

  onGetQualificationData,
  getQualificationRes,

  onPutQualificationData,

  getAllCountriesRes,
  onGetAllCountriesData,
  onGetVisaTypes,
  onUpdatePermissions,
  onGetJobALLStatuses,
  jobStatusSuccess,
}) => {
  const { t } = useTranslation();
  return (
    <div className="client-profile-tabs">
      <Tabs defaultActiveKey="1" type="card" size={"small"} className="mar-r">
        <TabPane tab={t("Client Information")} key="1">
          <PersonInformation
            onGetClientProfile={onGetClientProfile}
            clientProfile={clientProfile}
            onGetCustomFeild={onGetCustomFeild}
            CustomFeild={customFeild}
            onaddClientProfile={onaddClientProfile}
            onUpdateClientEmail={onUpdateClientEmail}
            onClientProfileSaveAddress={onClientProfileSaveAddress}
            onUpdateClientPhone={onUpdateClientPhone}
            onUpdateClientPassport={onUpdateClientPassport}
            onUpdateClientMedical={onUpdateClientMedical}
            getAllCountriesRes={getAllCountriesRes}
            onGetAllCountriesData={onGetAllCountriesData}
            onGetVisaTypes={onGetVisaTypes}
            onUpdatePermissions={onUpdatePermissions}
          />
        </TabPane>
        <TabPane tab={t("Employer Information")} key="2">
          <EmployerForm
            onGetEmployerInformation={onGetEmployerInformation}
            employerInformationRes={employerInformationRes}
            onAddemployerInformation={onAddemployerInformation}
            onUpdateEmployerInformation={onUpdateEmployerInformation}
            onGetClientProfile={onGetClientProfile}
            clientProfile={clientProfile}
            onGetJobALLStatuses={onGetJobALLStatuses}
            jobStatusSuccess={jobStatusSuccess}
          />
        </TabPane>
        <TabPane tab={t("Job History")} key="3">
          <JobHistory
            onaddJobHistory={onaddJobHistory}
            onGetClientProfile={onGetClientProfile}
            clientProfile={clientProfile}
            onGetJobHistoryData={onGetJobHistoryData}
            getJobHistoryRes={getJobHistoryRes}
            onPutJobHistoryData={onPutJobHistoryData}
            onGetEmployerInformation={onGetEmployerInformation}
            employerInformationRes={employerInformationRes}
            onGetJobALLStatuses={onGetJobALLStatuses}
            jobStatusSuccess={jobStatusSuccess}
          />
        </TabPane>
        <TabPane tab={t("Qualification")} key="4">
          <Qualification
            clientProfile={clientProfile}
            onGetClientProfile={onGetClientProfile}
            onAddQualificaion={onAddQualificaion}
            onGetQualificationData={onGetQualificationData}
            getQualificationRes={getQualificationRes}
            onPutQualificationData={onPutQualificationData}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ClientProfileTabList;
