import React, { Fragment, useState, useEffect } from "react";
import ClientProfile from "./clientProfile";
const PersonInformation = ({
  onGetClientProfile,
  clientProfile,
  onGetCustomFeild,
  customFeild,
  onaddClientProfile,
  onUpdateClientEmail,
  onClientProfileSaveAddress,
  onUpdateClientPhone,
  onUpdateClientPassport,
  onUpdateClientMedical,

  getAllCountriesRes,
  onGetAllCountriesData,
  onGetVisaTypes,
  onUpdatePermissions,
}) => {
  const [canUpdate, setUpdateVal] = useState(null);
  useEffect(() => {
    onGetClientProfile().then((res) => {
      localStorage.setItem("userDetail", JSON.stringify(res.payload));
    });
  }, []);
  useEffect(() => {
    if (clientProfile) {
      let { clientPermission } = clientProfile;
      let { allowUpdate } = clientPermission;
      setUpdateVal(allowUpdate);
    }
  }, [clientProfile]);
  console.log("clientProfile 123123 ", clientProfile);
  return (
    <Fragment>
      <ClientProfile
        onGetClientProfile={onGetClientProfile}
        clientProfile={clientProfile}
        onGetCustomFeild={onGetCustomFeild}
        customFeild={customFeild}
        onaddClientProfile={onaddClientProfile}
        onUpdateClientEmail={onUpdateClientEmail}
        onClientProfileSaveAddress={onClientProfileSaveAddress}
        onUpdateClientPhone={onUpdateClientPhone}
        getAllCountriesRes={getAllCountriesRes}
        onGetAllCountriesData={onGetAllCountriesData}
        canUpdate={canUpdate}
        setUpdateVal={setUpdateVal}
        onGetVisaTypes={onGetVisaTypes}
      />
    </Fragment>
  );
};

export default PersonInformation;
