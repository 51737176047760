import axios from "axios";
import { createBrowserHistory } from "history";
import { message } from "antd";
import * as types from "../store/Constants";
const history = createBrowserHistory();
const baseURL = "https://linuxapi-stage.ezymigrate.co.nz/";
// const baseURL = "https://uatapi.ezymigrate.co.nz/";
let token = window.localStorage.getItem("AccessToken");
let accessToken = token ? JSON.parse(token) : null;
let RefreshToken = true;
let SessionDeath = true;

let CallStack = [];
const onClose = () => {
  localStorage.clear();
  // history.location.push("/sign-up");
  window.location.reload();
};

const api = {
  authLogin:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  get: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 1, options, null);
          // if(err.response&&err.response.status===401){
          //   localStorage.clear()
          //   window.location.assign("/login");
          // }
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  getDocument: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 1, options, null);

          // if(err.response.status===401){
          //   localStorage.clear()
          //   window.location.assign("/login");
          // }
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  post:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            refreshTokenData(err, 2, options, params);
            // if(err.response.status===401){
            //   localStorage.clear()
            //   window.location.assign("/login");
            // }
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  uploadPost:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          mode: "no-cors",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            contentType: "application/octet-stream",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            if (err.response.status === 401) {
              localStorage.clear();
              window.location.assign("/login");
            }
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  put:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "PUT",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            refreshTokenData(err, 3, options, params);

            // if(err.response.status===401){
            //   localStorage.clear()
            //   window.location.assign("/login");
            // }
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  patch:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "PATCH",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  delete:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "DELETE",
          url: `${baseURL}${options.url}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            refreshTokenData(err, 4, options, params);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
};

const refreshTokenData = async (err, apiType, opt, param) => {
  if (
    err &&
    err.response &&
    err.response.status === 401 &&
    err.response.data &&
    err.response.data.code == 2
  ) {
    if (RefreshToken) {
      RefreshToken = false;

      const options = {
        url: "v1/user/identity/RefreshToken",
      };

      options.types = [types.REFRESH_SUCCESS, types.REFRESH_FAILURE];
      const refreshToken = localStorage.getItem("refreshToken");
      if (
        refreshToken == null ||
        refreshToken == "undefined" ||
        !refreshToken
      ) {
        localStorage.clear();
        window.location.assign("/login");
      }
      var data = { refreshToken: refreshToken };
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          localStorage.setItem(
            "AccessToken",
            JSON.stringify(res.data.accessToken)
          );
          localStorage.setItem("refreshToken", res.data.refreshToken);
          if (apiType == 1) {
            api.get(opt);
          } else if (apiType == 2) {
            api.post(opt, param);
          } else if (apiType == 3) {
            api.put(opt, param);
          } else if (apiType == 4) {
            api.delete(opt, param);
          }
          for (let i = 0; i < CallStack.length; i++) {
            if (CallStack[i].apiType == 1) {
              api.get(CallStack[i].opt);
            } else if (CallStack[i].apiType == 2) {
              api.post(CallStack[i].opt, CallStack[i].param);
            } else if (CallStack[i].apiType == 3) {
              api.put(CallStack[i].opt, CallStack[i].param);
            } else if (CallStack[i].apiType == 4) {
              api.delete(CallStack[i].opt, CallStack[i].param);
            }
          }
          RefreshToken = false;
          CallStack = [];
          window.location.reload();
        })
        .catch(async (err) => {
          err && localStorage.clear();
          window.location.assign("/login");
        });
    } else {
      var data = {
        apiType: apiType,
        opt: opt,
        param: param,
      };
      CallStack.push(data);
    }
  } else if (
    err &&
    err.response &&
    err.response.status === 401 &&
    err.response.data &&
    (err.response.data.code == 3 || err.response.data.code == 0) &&
    SessionDeath
  ) {
    SessionDeath = false;
    message.error("Your session has expired please login again", 5, onClose);
  } else if (err && err.response && err.response.status === 500) {
    message.error(
      "Oops! Something went wrong! Please contact our Support Team!",
      5
    );
  }
};

export { api };
