import React, { Fragment, useState, useEffect } from "react";

import {
  Avatar,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Spin,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { useTranslation } from "react-i18next";
import { yyyymmddFormate } from "./../../../Common/reUseFunctions";
import { medicialGrading } from "../../../../statics/data/medicialGrading";
import { medicalNotes } from "../../../../statics/data/medicalNotes";

const { Option } = Select;
const PersonalInformation = ({
  onGetClientProfile,
  clientProfile,
  onGetCustomFeild,
  customFeild,
  onaddClientProfile,
  onUpdateClientEmail,
  onClientProfileSaveAddress,
  onUpdateClientPhone,
  getAllCountriesRes,
  onGetAllCountriesData,
  canUpdate,
  setUpdateVal,
  onGetVisaTypes,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [img, setImg] = useState(null);
  const [visaTypes, setVisaTypes] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [customFielddata, setcustomFielddata] = useState([]);
  useEffect(() => {
    
    setLoading(true);
    onGetCustomFeild().then((res)=>{
      debugger
      setcustomFielddata(res.payload.clientProfileSetting);
    });
    onGetAllCountriesData()
      .then(() => {
        // setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    if (localStorage.getItem("userDetail")) {
      onGetVisaTypes().then((res) => {
        let { items } = res.payload;
        setVisaTypes(items);
      });
    }
    if (clientProfile) {
      sessionStorage.setItem(
        "profilePermission",
        JSON.stringify(clientProfile.clientPermission)
      );
      setLoading(false);
    }

  }, [onGetClientProfile, onGetAllCountriesData, clientProfile , customFeild]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
    
  //   // Fields Set Form Start //
  //   const emailDataAddress =
  //     clientProfile &&
  //     clientProfile.emails[0] &&
  //     clientProfile.emails[0].address;

  //   const clientDob =
  //     clientProfile && clientProfile.dateOfBirth === "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile && new moment(clientProfile.dateOfBirth);

  //   const addressType =
  //     clientProfile && clientProfile.addresses[0]
  //       ? clientProfile.addresses[0].city
  //       : "";
  //   const gender =
  //     clientProfile && clientProfile.gender === 0
  //       ? undefined
  //       : clientProfile && clientProfile.gender;
  //   const imageUrl = clientProfile && clientProfile.imageBlobUrl;
  //   if (imageUrl) setImg(imageUrl);
  //   const passportIssueDate =
  //     clientProfile &&
  //     clientProfile.passports[0] &&
  //     clientProfile.passports[0].passportIssueDate ===
  //       "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile &&
  //         clientProfile.passports[0] &&
  //         clientProfile.passports[0].passportIssueDate &&
  //         new moment(clientProfile.passports[0].passportIssueDate);
  //   const passport_expiry_date =
  //     clientProfile &&
  //     clientProfile.passports[0] &&
  //     clientProfile.passports[0].passportExpiryDate ===
  //       "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile &&
  //         clientProfile.passports[0] &&
  //         clientProfile.passports[0].passportExpiryDate &&
  //         new moment(clientProfile.passports[0].passportExpiryDate);
  //   const second_passport_issue_date =
  //     clientProfile &&
  //     clientProfile.passports[1] &&
  //     clientProfile.passports[1].passportIssueDate ===
  //       "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile &&
  //         clientProfile.passports[1] &&
  //         clientProfile.passports[1].passportIssueDate &&
  //         new moment(clientProfile.passports[1].passportIssueDate);
  //   const second_passport_expiry_date =
  //     clientProfile &&
  //     clientProfile.passports[1] &&
  //     clientProfile.passports[1].passportExpiryDate ===
  //       "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile &&
  //         clientProfile.passports[1] &&
  //         clientProfile.passports[1].passportExpiryDate &&
  //         new moment(clientProfile.passports[1].passportExpiryDate);
  //   const current_newzeland_visa_expiry =
  //     clientProfile &&
  //     clientProfile.currentNewZealandVisaExpiry &&
  //     clientProfile.currentNewZealandVisaExpiry === "1900-01-01T00:00:00+00:00"
  //       ? ""
  //       : clientProfile &&
  //         clientProfile.currentNewZealandVisaExpiry &&
  //         clientProfile.currentNewZealandVisaExpiry &&
  //         new moment(clientProfile.currentNewZealandVisaExpiry);
  //   const medicalIssueDate =
  //     clientProfile &&
  //     clientProfile.medicals &&
  //     clientProfile.medicals.length > 0
  //       ? clientProfile.medicals[0].medicalIssueDate &&
  //         clientProfile.medicals[0].medicalIssueDate ===
  //           "1900-01-01T00:00:00+00:00"
  //         ? ""
  //         : clientProfile &&
  //           clientProfile.medicals[0].medicalIssueDate &&
  //           clientProfile.medicals[0].medicalIssueDate &&
  //           new moment(clientProfile.medicals[0].medicalIssueDate)
  //       : "";
  //   const medicalExpiryDate =
  //     clientProfile &&
  //     clientProfile.medicals &&
  //     clientProfile.medicals.length > 0
  //       ? clientProfile.medicals[0].medicalExpiryDate &&
  //         clientProfile.medicals[0].medicalExpiryDate ===
  //           "1900-01-01T00:00:00+00:00"
  //         ? ""
  //         : clientProfile &&
  //           clientProfile.medicals[0].medicalExpiryDate &&
  //           clientProfile.medicals[0].medicalExpiryDate &&
  //           new moment(clientProfile.medicals[0].medicalExpiryDate)
  //       : "";
  //   const xrayIssueDate =
  //     clientProfile &&
  //     clientProfile.medicals &&
  //     clientProfile.medicals.length > 0
  //       ? clientProfile.medicals[0].xrayIssueDate &&
  //         clientProfile.medicals[0].xrayIssueDate ===
  //           "1900-01-01T00:00:00+00:00"
  //         ? ""
  //         : clientProfile &&
  //           clientProfile.medicals[0].xrayIssueDate &&
  //           clientProfile.medicals[0].xrayIssueDate &&
  //           new moment(clientProfile.medicals[0].xrayIssueDate)
  //       : "";
  //   const xrayExpiryDate =
  //     clientProfile &&
  //     clientProfile.medicals &&
  //     clientProfile.medicals.length > 0
  //       ? clientProfile.medicals[0].xrayExpiryDate &&
  //         clientProfile.medicals[0].xrayExpiryDate ===
  //           "1900-01-01T00:00:00+00:00"
  //         ? ""
  //         : clientProfile &&
  //           clientProfile.medicals[0].xrayExpiryDate &&
  //           clientProfile.medicals[0].xrayExpiryDate &&
  //           new moment(clientProfile.medicals[0].xrayExpiryDate)
  //       : "";
  //   const country_Issue =
  //     clientProfile && clientProfile.passports.length
  //       ? getAllCountriesRes &&
  //         getAllCountriesRes.items.filter(
  //           (el) => el.id === clientProfile.passports[0].passportCountry
  //         )
  //       : [];
  //   const second_country_of_issue =
  //     clientProfile && clientProfile.passports.length > 1
  //       ? getAllCountriesRes &&
  //         getAllCountriesRes.items.filter(
  //           (el) => el.id === clientProfile.passports[1].passportCountry
  //         )
  //       : [];

  //   form.setFieldsValue({
  //     title: clientProfile && clientProfile.title,
  //     first_name: clientProfile && clientProfile.firstName,
  //     middle_name: clientProfile && clientProfile.middleName,
  //     last_name: clientProfile && clientProfile.lastName,
  //     other_name: clientProfile && clientProfile.preferredName,
  //     gender: gender,
  //     email: emailDataAddress,
  //     client_dob: clientDob,
  //     address: addressType,
  //     mobile_phone:
  //       clientProfile &&
  //       clientProfile.phones[0] &&
  //       clientProfile.phones[0].contact.trim(),
  //     secondary_mobile:
  //       clientProfile &&
  //       clientProfile.phones[1] &&
  //       clientProfile.phones[1].contact.trim(),
  //     overseas_mobile:
  //       clientProfile &&
  //       clientProfile.phones[2] &&
  //       clientProfile.phones[2].contact.trim(),
  //     land_line:
  //       clientProfile &&
  //       clientProfile.phones[3] &&
  //       clientProfile.phones[3].contact.trim(),
  //     other_contact_information:
  //       clientProfile &&
  //       clientProfile.phones[4] &&
  //       clientProfile.phones[4].contact.trim(),
  //     material_status: clientProfile && clientProfile.maritalStatus,
  //     dependent_children: clientProfile && clientProfile.dependentChildren,
  //     occupation: clientProfile && clientProfile.occupation,
  //     company: clientProfile && clientProfile.companyOptional,
  //     passport_issue_date: passportIssueDate,
  //     passport_number:
  //       clientProfile &&
  //       clientProfile.passports[0] &&
  //       clientProfile.passports[0].passportNo,
  //     passport_expiry_date: passport_expiry_date,
  //     second_passport_issue_date: second_passport_issue_date,
  //     second_passport_expiry_date: second_passport_expiry_date,
  //     second_passport_number:
  //       clientProfile &&
  //       clientProfile.passports[1] &&
  //       clientProfile.passports[1].passportNo,
  //     current_newzeland_visa_expiry: current_newzeland_visa_expiry,
  //     medical_certificate_issue_date: medicalIssueDate,
  //     medical_certificate_expiry_date: medicalExpiryDate,
  //     medical_grading:
  //       clientProfile &&
  //       clientProfile.medicals &&
  //       clientProfile.medicals.length > 0
  //         ? clientProfile.medicals[0].medicalGrading
  //         : "",
  //     xray_grading:
  //       clientProfile &&
  //       clientProfile.medicals &&
  //       clientProfile.medicals.length > 0
  //         ? clientProfile.medicals[0].xrayGrading
  //         : "",
  //     medical_notes:
  //       clientProfile &&
  //       clientProfile.medicals &&
  //       clientProfile.medicals.length > 0
  //         ? clientProfile.medicals[0].medicalNotes
  //         : "",
  //     nzer:
  //       clientProfile &&
  //       clientProfile.medicals &&
  //       clientProfile.medicals.length > 0
  //         ? clientProfile.medicals[0].er
  //         : "",
  //     current_visa_type: clientProfile && clientProfile.currentVisaTypeId,
  //     xray_issue_date: xrayIssueDate,
  //     xray_expiry_date: xrayExpiryDate,
  //     country_Issue:
  //       country_Issue && country_Issue.length
  //         ? country_Issue[0].name === "No Country"
  //           ? undefined
  //           : country_Issue[0].name
  //         : undefined,
  //     second_country_of_issue:
  //       second_country_of_issue && second_country_of_issue.length
  //         ? second_country_of_issue[0].name === "No Country"
  //           ? undefined
  //           : second_country_of_issue[0].name
  //         : undefined,
  //   });
  // }, [clientProfile]);


  ///Custom Fields 
  // useEffect(()=>{
  //   debugger
  //   const hasClientProfileSetting = customFeild;
  // },[customFeild]);

  const onFinish = (values) => {
    const { familyId } = JSON.parse(localStorage.getItem("userDetail"));
    setLoading(true);
    // Personal Information Start //
    const numberOfChieldren = parseInt(values.dependent_children);
    const medicalId =
      clientProfile && clientProfile.medicals.length > 0
        ? clientProfile.medicals[0].id
        : 0;
    const medicalClientId =
      clientProfile && clientProfile.medicals.length > 0
        ? clientProfile.medicals[0].clientId
        : clientProfile && clientProfile.id;
    const passportId =
      clientProfile &&
      clientProfile.passports &&
      clientProfile.passports.length > 0
        ? clientProfile.passports[0].id
        : 0;
    const secondpassportId =
      clientProfile &&
      clientProfile.passports &&
      clientProfile.passports.length > 1
        ? clientProfile.passports[1].id
        : 0;
    const passportClientId =
      clientProfile &&
      clientProfile.passports &&
      clientProfile.passports.length > 0
        ? clientProfile.passports[0].clientId
        : clientProfile.id;
    const passportModifiedBy =
      clientProfile &&
      clientProfile.passports &&
      clientProfile.passports.length > 0
        ? clientProfile.passports[0].modifiedBy
        : clientProfile.modifiedBy;
    const data = {
      id: clientProfile && clientProfile.id,
      branchId: clientProfile && clientProfile.branchId,
      clientNumber: "",
      familyId: familyId,
      processingGroupId: 0,
      agentId: clientProfile && clientProfile.agentId,
      clientTag: 0,
      firstName: values.first_name,
      lastName: values.last_name,
      middleName: values.middle_name ? values.middle_name : "",
      title: values.title ? values.title : "",
      gender: values.gender ? values.gender : 0,
      dateOfBirth:
        values.client_dob === ""
          ? "1900-01-01T00:00:00+00:00"
          : values.client_dob,
      maritalStatus: values.material_status,
      dependentChildren: numberOfChieldren,
      notes: "",
      occupation: values.occupation,
      occupationOrganization: values.company ? values.company : "",
      inzUserName: "",
      inzPassword: "",
      imageBlobUrl: "",
      nationalityId: "",
      nationalityCountry: "",
      skypeID: "",
      preferredName: values.other_name ? values.other_name : "",
      isSubscribed: true,
      arbitaryJson: "",
      dependentClientIds: "",
      modifiedBy: clientProfile && clientProfile.clientId,
      currentVisaTypeId: values.current_visa_type
        ? values.current_visa_type
        : 0,
      currentNewZealandVisaExpiry: values.current_newzeland_visa_expiry
        ? values.current_newzeland_visa_expiry
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo:
        clientProfile && clientProfile.travelConditionsValidTo,
      visaText: clientProfile && clientProfile.visaText,
      visaDenied: clientProfile && clientProfile.visaDenied,
      deniedText: clientProfile && clientProfile.deniedText,
      clientNumberIZM: "",
      inzFeeDate: clientProfile && clientProfile.inzFeeDate,
      memberType: "Client",
      clientId: clientProfile && clientProfile.clientId,
      nzqaOnlineSubDate: "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: "1900-01-01T00:00:00+00:00",
      visaCountryId: clientProfile && clientProfile.visaCountryId,
      visaCountyType: clientProfile && clientProfile.visaCountyType,
      age: clientProfile && clientProfile.age,
      jobSectorId: clientProfile && clientProfile.jobSectorId,
      sourceId: clientProfile && clientProfile.sourceId,
      sourceDescription: "",
      clientSerial: "",
      companyOptional: values.company ? values.company : "",
      dealWorth: "",
      saleDate: clientProfile && clientProfile.saleDate,
      clientPermission: clientProfile.clientPermission,
      clientMedical: [
        {
          id: medicalId,
          clientId: medicalClientId,
          er: values.nzer ? values.nzer : "",
          medicalIssueDate:
            values.medical_certificate_issue_date &&
            values.medical_certificate_issue_date
              ? values.medical_certificate_issue_date.toISOString()
              : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate:
            values.medical_certificate_expiry_date &&
            values.medical_certificate_expiry_date
              ? values.medical_certificate_expiry_date.toISOString()
              : "1900-01-01T00:00:00+00:00",
          xrayIssueDate:
            values.xray_issue_date && values.xray_issue_date
              ? values.xray_issue_date.toISOString()
              : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate:
            values.xray_expiry_date && values.xray_expiry_date
              ? values.xray_expiry_date.toISOString()
              : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medical_grading ? values.medical_grading : "",
          xrayGrading: values.xray_grading ? values.xray_grading : "",
          medicalNotes: values.medical_notes ? values.medical_notes : "",
          medicalNotesDetail: outputHTML,
          modifiedBy: medicalClientId,
        },
      ],
      clientEmails: [],
      addresses: [],
      clientPhones: [],
      passports: [],
      policeCertificates: [],
    };
    data.addresses.push({
      id:
        clientProfile && clientProfile.addresses.length
          ? clientProfile.addresses[0].id
          : 0,
      clientId: clientProfile && clientProfile.id,
      contactPerson: values && values.mobile_phone ? values.mobile_phone : "",
      flat: "",
      building: "",
      streetName: "",
      suburb: "",
      streetNumber: "",
      city: values.address && values.address.length ? values.address : "",
      state: "",
      zip: "",
      country: 0,
      addressTypeId: 1,
      modifiedBy: clientProfile && clientProfile.id,
    });

    if (
      clientProfile.phones.length ||
      (values.mobile_phone && values.mobile_phone.length)
    )
      data.clientPhones.push({
        id: clientProfile.phones.length ? clientProfile.phones[0].id : 0,
        clientId: clientProfile.id,
        countryCodeId: 168,
        contact: values.mobile_phone ? values.mobile_phone : "",
        phoneTypeId: 1,
        modifiedBy: clientProfile && clientProfile.id,
      });
    if (clientProfile.phones.length >= 2)
      data.clientPhones.push({
        id: clientProfile.phones[1].id,
        clientId: clientProfile.id,
        countryCodeId: 168,
        contact: values.secondary_mobile ? values.secondary_mobile : "",
        phoneTypeId: 2,
        modifiedBy: clientProfile && clientProfile.id,
      });
    if (clientProfile.phones.length >= 3)
      data.clientPhones.push({
        id: clientProfile.phones[2].id,
        clientId: clientProfile.id,
        countryCodeId: 168,
        contact: values.overseas_mobile ? values.overseas_mobile : "",
        phoneTypeId: 3,
        modifiedBy: clientProfile && clientProfile.id,
      });
    if (clientProfile.phones.length >= 4)
      data.clientPhones.push({
        id: clientProfile.phones[3].id,
        clientId: clientProfile.id,
        countryCodeId: 168,
        contact: values.land_line ? values.land_line : "",
        phoneTypeId: 4,
        modifiedBy: clientProfile && clientProfile.id,
      });
    if (clientProfile.phones.length >= 5)
      data.clientPhones.push({
        id: clientProfile.phones[4] ? clientProfile.phones[4].id : 0,
        clientId: clientProfile.id,
        countryCodeId: 168,
        contact: values.other_contact_information
          ? values.other_contact_information
          : "",
        phoneTypeId: 5,
        modifiedBy: clientProfile && clientProfile.id,
      });

    if (values.passport_number && values.passport_number.length) {
      data.passports.push({
        id: passportId,
        clientId: passportClientId,
        passportNo: values.passport_number ? values.passport_number : "",
        passportCountry:
          values && values.country_Issue === "No Country"
            ? 0
            : Number.isInteger(values.country_Issue)
            ? values.country_Issue
            : clientProfile.passports[0].passportCountry,
        passportIssueDate:
          values.passport_issue_date && values.passport_issue_date
            ? values.passport_issue_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
        passportExpiryDate:
          values.passport_expiry_date && values.passport_expiry_date
            ? values.passport_expiry_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
        modifiedBy: passportModifiedBy,
        passportType: 1,
      });
    }
    if (values.second_passport_number && values.second_passport_number.length) {
      data.passports.push({
        id: secondpassportId,
        clientId: passportClientId,
        passportNo: values.second_passport_number,
        passportCountry:
          values && values.second_country_of_issue === "No Country"
            ? 0
            : Number.isInteger(values.second_country_of_issue)
            ? values.second_country_of_issue
            : clientProfile.passports[1].passportCountry,
        passportIssueDate:
          values.second_passport_issue_date && values.second_passport_issue_date
            ? values.second_passport_issue_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
        passportExpiryDate:
          values.second_passport_expiry_date &&
          values.second_passport_expiry_date
            ? values.second_passport_expiry_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
        modifiedBy: passportModifiedBy,
        passportType: 2,
      });
    }
    clientProfile.emails.length &&
      data.clientEmails.push({
        id: clientProfile.emails[0].id,
        clientId: clientProfile.id,
        address: values.email ? values.email : "",
        emailTypeId: 1,
        createdBy: clientProfile && clientProfile.id,
      });

    onaddClientProfile(data)
      .then(() => {
        onGetClientProfile();
        setLoading(false);
        message.success("Successfully Updated!");
      })
      .catch(() => {
        setLoading(false);
      });
    // Personal Information End //

    console.log("Received values of form: ", values);
  };
  const getValidCountry = (data) => (data === 0 ? "" : data);

  const getValidDate = (date) =>
    date === "1900-01-01T00:00:00+00:00"
      ? ""
      : yyyymmddFormate(clientProfile.passports[0].passportIssueDate);
  console.log("clientProfile 123123 ", clientProfile);
  const getVisaName = (currentVisaTypeId) => {
    let visa = visaTypes.filter((el) => el.id === currentVisaTypeId);
    return visa.length ? visa[0]["visaTypeName"] : "";
  };
  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };


  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          form={form}
        >
           
      {/* Personal Information */}
      {
      customFielddata &&
      customFielddata.length > 0 &&
      !customFielddata.find(
        (obj) => obj.type === "Personal Information"
      ).status ?  (
        //false
        <>
        {/* PERSONAL INFORMATION */}
        <div className="container client-info-tab information-area-bg padding-tb">
         <div className="client-information-container">
           <div className="align-center mb-30">
             <h2 className="info-heading">{t("PERSONAL INFORMATION")}</h2>
           </div>
           <div className="align-center">
             <Avatar
               src={
                 img
                   ? img
                   : "https://www.shareicon.net/data/2016/05/26/771187_man_512x512.png"
               }
               size={95}
               icon={<UserOutlined />}
             />
           </div>
           <div className="information-area">
             <ul>
               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Title")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>{clientProfile && clientProfile.title}</span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="title"
                       rules={[
                         {
                           required: false,
                           message: "Please input your title!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li> */}
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Client's First Name")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>{clientProfile && clientProfile.firstName}</span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="first_name"
                       rules={[
                         {
                           required: false,
                           message: "Please input your first name!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Middle Name")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>{clientProfile && clientProfile.middleName}</span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="middle_name"
                       rules={[
                         {
                           required: false,
                           message: "Please input your middle name!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Last Name")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>{clientProfile && clientProfile.lastName}</span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="last_name"
                       rules={[
                         {
                           required: false,
                           message: "Please input your last name!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Other Name")}</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile && clientProfile.preferredName}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="other_name"
                       rules={[
                         {
                           required: false,
                           message: "Please input your other name!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li> */}
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Gender")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile && clientProfile.gender === 1
                         ? "Male"
                         : clientProfile && clientProfile.gender === 2
                         ? "Female"
                         : ""}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="gender"
                       rules={[
                         {
                           required: false,
                           message: "Please select your gender!",
                         },
                       ]}
                     >
                       <Select>
                         <Option value={1}>Male</Option>
                         <Option value={2}>Female</Option>
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li>

               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Email")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.emails &&
                         clientProfile.emails.length > 0 &&
                         clientProfile.emails[0].address}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="email"
                       rules={[
                         {
                           required: false,
                           message:
                             "Please input your second passport number!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Date of Birth")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item name="client_dob">
                       <DatePicker disabled format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                   {canUpdate && (
                     <Form.Item name="client_dob" className="profile-form">
                       <DatePicker format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Address")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.addresses.map((address) => {
                           if (address.addressTypeId == 1) {
                             return address.city;
                           }
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="address"
                       rules={[
                         {
                           required: false,
                           message: "Please input your Street Address!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("City")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.addresses.map((address) => {
                           return address.city;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="city"
                       rules={[
                         {
                           required: false,
                           message: "Please input your City!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li> */}
               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("State/Province")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.addresses.map((address) => {
                           return address.state;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="state"
                       rules={[
                         {
                           required: false,
                           message: "Please input your State / Province!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li> */}
               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Postal/Zip Code")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.addresses.map((address) => {
                           return address.zip;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="zipCode"
                       rules={[
                         {
                           required: false,
                           message: "Please input your Postal / Zip Code!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li> */}

               {/* <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Country")} :</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item
                       name="country"
                       rules={[
                         {
                           required: false,
                           message: "Please select your Country!",
                         },
                       ]}
                     >
                       <Input readOnly />
                     </Form.Item>
                    
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="country"
                       rules={[
                         {
                           required: false,
                           message: "Please select your Country!",
                         },
                       ]}
                     >
                       <Select
                         placeholder="Select"
                         style={{ width: "100%" }}
                         showSearch
                         filterOption={(input, option) =>
                           option.children
                             .toLowerCase()
                             .indexOf(input.toLowerCase()) >= 0
                         }
                         getPopupContainer={(trigger) =>
                           trigger.parentElement
                         }
                       >
                         {getAllCountriesRes &&
                           getAllCountriesRes.items &&
                           getAllCountriesRes.items.length > 0 &&
                           getAllCountriesRes.items.map((item, index) => (
                             <Option key={index} value={item.id}>
                               {item.name}
                             </Option>
                           ))}
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li> */}

               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Mobile Phone")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.phones.map((item) => {
                           return item.phoneTypeId === 1 && item.contact;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="mobile_phone"
                       rules={[
                         {
                           required: false,
                           message: "Please input your mobile_phone!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Secondary Mobile")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.phones.map((item) => {
                           return item.phoneTypeId === 2 && item.contact;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="secondary_mobile"
                       rules={[
                         {
                           required: false,
                           message: "Please input your secondary mobile!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Overseas Mobile")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.phones.map((item) => {
                           return item.phoneTypeId === 3 && item.contact;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="overseas_mobile"
                       rules={[
                         {
                           required: false,
                           message: "Please input your overseas mobile!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>

               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Land Line")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.phones.map((item) => {
                           return item.phoneTypeId === 4 && item.contact;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="land_line"
                       rules={[
                         {
                           required: false,
                           message: "Please input your land line!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">
                   {t("Other Contact Information")}
                 </span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         clientProfile.phones.map((item) => {
                           return item.phoneTypeId === 5 && item.contact;
                         })}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="other_contact_information"
                       rules={[
                         {
                           required: false,
                           message:
                             "Please input your other contact information!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Marital Status")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile && clientProfile.maritalStatus}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="material_status"
                       rules={[
                         {
                           required: false,
                           message:
                             "Please input your other contact information!",
                         },
                       ]}
                     >
                       <Select>
                         <Option value="married">Married</Option>
                         <Option value="unmarried">UnMarried</Option>
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">
                   {t("Number of dependent children")}:
                 </span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile &&
                         getValidCountry(clientProfile.dependentChildren)}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="dependent_children"
                       rules={[
                         {
                           required: false,
                           message:
                             "Please input your number of dependent children!",
                         },
                       ]}
                     >
                       <Input type="number" />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Occupation")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>{clientProfile && clientProfile.occupation}</span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="occupation"
                       rules={[
                         {
                           required: false,
                           message: "Please input your Occupation!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Company")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <span>
                       {clientProfile && clientProfile.companyOptional}
                     </span>
                   )}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="company"
                       rules={[
                         {
                           required: false,
                           message: "Please input your Company!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
             </ul>
           </div>
         </div>
       </div>
       </>
        ) : (
          //true
       <></>
        )
      }

    {/* passport  */}
    {
      customFielddata &&
      customFielddata.length > 0 &&
      !customFielddata.find(
        (obj) => obj.type === "Passport Detail"
      ).status ?  (
        //false
        <>
        {/* 
         passport section
          */}
         <div className="container client-info-tab information-area-bg mar-top padding-tb">
           <div className="client-information-container">
             <div className="align-center">
               <h2 className="info-heading">{t("PASSPORT DETAIL")}</h2>
             </div>

             <div className="information-area">
               <ul>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">{t("Passport Number")}:</span>
                   <div className="w40-40">
                     {!canUpdate &&
                       clientProfile &&
                       clientProfile.passports[0] &&
                       clientProfile.passports[0].passportNo}
                     {canUpdate && (
                       <Form.Item
                         className="profile-form"
                         name="passport_number"
                         rules={[
                           {
                             required: false,
                             message: "Please input your passport number!",
                           },
                         ]}
                       >
                         <Input />
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">{t("Country Of Issue")}:</span>
                   <div className="w40-40">
                     {
                       !canUpdate && (
                         <Form.Item
                           name="country_Issue"
                           rules={[
                             {
                               required: false,
                               message: "Please input your select gender!",
                             },
                           ]}
                         >
                           <Input readOnly />
                         </Form.Item>
                       )
                       // clientProfile &&
                       // clientProfile.passports[0] &&
                       // getValidCountry(
                       //   clientProfile.passports[0].passportCountry
                       // )
                     }
                     {canUpdate && (
                       <Form.Item
                         className="profile-form"
                         name="country_Issue"
                         rules={[
                           {
                             required: false,
                             message: "Please input your select gender!",
                           },
                         ]}
                       >
                         <Select
                           placeholder="Select"
                           style={{ width: "100%" }}
                           showSearch
                           filterOption={(input, option) =>
                             option.children
                               .toLowerCase()
                               .indexOf(input.toLowerCase()) >= 0
                           }
                           getPopupContainer={(trigger) =>
                             trigger.parentElement
                           }
                         >
                           {getAllCountriesRes &&
                             getAllCountriesRes.items &&
                             getAllCountriesRes.items.length > 0 &&
                             getAllCountriesRes.items.map((item, index) => (
                               <Option key={index} value={item.id}>
                                 {item.name}
                               </Option>
                             ))}
                         </Select>
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">{t("Passport Issue Date")}:</span>
                   <div className="w40-40">
                     {!canUpdate && (
                       <Form.Item name="passport_issue_date">
                         <DatePicker disabled format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                     {canUpdate && (
                       <Form.Item
                         name="passport_issue_date"
                         className="profile-form"
                       >
                         <DatePicker format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">
                     {t("Passport Expiry Date")}:
                   </span>
                   <div className="w40-40">
                     {!canUpdate && (
                       <Form.Item name="passport_expiry_date">
                         <DatePicker disabled format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                     {canUpdate && (
                       <Form.Item
                         name="passport_expiry_date"
                         className="profile-form"
                       >
                         <DatePicker format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                   </div>
                 </li>
               </ul>
             </div>
           </div>
         </div>
         {/* Second Passport Number */}
         <div className="container client-info-tab information-area-bg mar-top padding-tb">
           <div className="client-information-container">
             <div className="align-center">
               <h1 className="info-heading">
                 {t("SECOND PASSPORT DETAIL (IF CLIENT IS DUAL NATIONAL)")}
               </h1>
             </div>
             <div className="information-area">
               <ul>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">
                     {t("Second Passport Number")}:
                   </span>
                   <div className="w40-40">
                     {!canUpdate &&
                       clientProfile &&
                       clientProfile.passports[1] &&
                       clientProfile.passports[1].passportNo}
                     {canUpdate && (
                       <Form.Item
                         className="profile-form"
                         name="second_passport_number"
                         rules={[
                           {
                             required: false,
                             message:
                               "Please input your second passport number!",
                           },
                         ]}
                       >
                         <Input />
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">
                     {t("Second Country Of Issue")}:
                   </span>
                   <div className="w40-40">
                     {
                       !canUpdate && (
                         <Form.Item
                           // className="profile-form"
                           name="second_country_of_issue"
                           rules={[
                             {
                               required: false,
                               message:
                                 "Please input your select second country of issue!",
                             },
                           ]}
                         >
                           <Input />
                         </Form.Item>
                       )
                       // clientProfile &&
                       // clientProfile.passports[1] &&
                       // getValidCountry(
                       //   clientProfile.passports[1].passportCountry
                       // )
                     }
                     {canUpdate && (
                       <Form.Item
                         className="profile-form"
                         name="second_country_of_issue"
                         rules={[
                           {
                             required: false,
                             message:
                               "Please input your select second country of issue!",
                           },
                         ]}
                       >
                         <Select
                           placeholder="Select"
                           style={{ width: "100%" }}
                           showSearch
                           filterOption={(input, option) =>
                             option.children
                               .toLowerCase()
                               .indexOf(input.toLowerCase()) >= 0
                           }
                           getPopupContainer={(trigger) =>
                             trigger.parentElement
                           }
                         >
                           {getAllCountriesRes &&
                             getAllCountriesRes.items &&
                             getAllCountriesRes.items.length > 0 &&
                             getAllCountriesRes.items.map((item, index) => (
                               <Option key={index} value={item.id}>
                                 {item.name}
                               </Option>
                             ))}
                         </Select>
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">
                     {t("Second Passport Issue Date")}:
                   </span>
                   <div className="w40-40">
                     {!canUpdate && (
                       <Form.Item name="second_passport_issue_date">
                         <DatePicker disabled format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                     {canUpdate && (
                       <Form.Item
                         name="second_passport_issue_date"
                         className="profile-form"
                       >
                         <DatePicker format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                   </div>
                 </li>
                 <li className="d-flex client-information-setting">
                   <span className="w60-per">
                     {t("Second Passport Expiry Date")}:
                   </span>
                   <div className="w40-40">
                     {!canUpdate && (
                       <Form.Item name="second_passport_expiry_date">
                         <DatePicker disabled format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                     {canUpdate && (
                       <Form.Item
                         name="second_passport_expiry_date"
                         className="profile-form"
                       >
                         <DatePicker format={"DD/MM/YYYY"} />
                       </Form.Item>
                     )}
                   </div>
                 </li>
               </ul>
             </div>
           </div>
         </div>
         </>
        ) : (
          //true
          <></>
        )
    }
        
    {/* Current Visa Detail */}
    {
      customFielddata &&
      customFielddata.length > 0 &&
      !customFielddata.find(
        (obj) => obj.type === "Current Visa Detail"
      ).status ?  (
        //false
        <>
        {/* Current Visa Type */}
        <div className="container client-info-tab information-area-bg mar-top padding-tb">
          <div className="client-information-container">
            <div className="align-center">
              <h1 className="info-heading">{t("VISA DETAIL (IF KNOWN)")}</h1>
            </div>
            <div className="information-area">
              <ul>
                <li className="d-flex client-information-setting">
                  <span className="w60-per">{t("Current Visa Type")}:</span>
                  <div className="w40-40">
                    {!canUpdate && (
                      <span>
                        {clientProfile &&
                          getVisaName(clientProfile.currentVisaTypeId)}
                      </span>
                    )}
                    {canUpdate && (
                      <Form.Item
                        className="profile-form"
                        name="current_visa_type"
                        rules={[
                          {
                            required: false,
                            message: "Please input your current visa type!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          getPopupContainer={(trigger) =>
                            trigger.parentElement
                          }
                        >
                          {visaTypes &&
                            visaTypes.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.visaTypeName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                  </div>
                </li>
                <li className="d-flex client-information-setting">
                  <span className="w60-per">
                    {t("Current Visa Expiry")}
                    <br /> {t("Date")}:
                  </span>
                  <div className="w40-40">
                    {!canUpdate && (
                      <Form.Item name="current_newzeland_visa_expiry">
                        <DatePicker disabled format={"DD/MM/YYYY"} />
                      </Form.Item>
                    )}
                    {canUpdate && (
                      <Form.Item
                        name="current_newzeland_visa_expiry"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </>
        ) : (
          //true
         <></>
        )
    }
    
    {/* Medical Detail */}
    {
      customFielddata &&
      customFielddata.length > 0 &&
          !customFielddata.find(
            (obj) => obj.type === "Medical Detail"
          ).status ?  (
            //false
            <>
            <div className="container client-info-tab information-area-bg mar-top padding-tb">
         <div className="client-information-container">
           <div className="align-center">
             <h1 className="info-heading">{t("MEDICAL DETAIL")}</h1>
           </div>
           <div className="information-area">
             <ul>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">NZER:</span>
                 <div className="w40-40">
                   {!canUpdate &&
                     clientProfile &&
                     clientProfile.medicals &&
                     clientProfile.medicals.map((item, index) => {
                       return <span key={index}>{item.er}</span>;
                     })}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="nzer"
                       rules={[
                         {
                           required: false,
                           message: "Please input your NZER!",
                         },
                       ]}
                     >
                       <Input />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">
                   {t("Medical Certificate Issue Date")}:
                 </span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item name="medical_certificate_issue_date">
                       <DatePicker disabled format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                   {canUpdate && (
                     <Form.Item
                       name="medical_certificate_issue_date"
                       className="profile-form"
                     >
                       <DatePicker format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">
                   {t("Medical Certificate Expiry Date")}:
                 </span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item name="medical_certificate_expiry_date">
                       <DatePicker disabled format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                   {canUpdate && (
                     <Form.Item
                       name="medical_certificate_expiry_date"
                       className="profile-form"
                     >
                       <DatePicker format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Medical Grading")}:</span>
                 <div className="w40-40">
                   {!canUpdate &&
                     clientProfile &&
                     clientProfile.medicals &&
                     clientProfile.medicals.map((item, index) => {
                       return <span key={index}>{item.medicalGrading}</span>;
                     })}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="medical_grading"
                       rules={[
                         {
                           required: false,
                           message: "Please input your medical grading!",
                         },
                       ]}
                     >
                       <Select
                         placeholder="Select"
                         style={{ width: "100%" }}
                         showSearch
                         filterOption={(input, option) =>
                           option.children
                             .toLowerCase()
                             .indexOf(input.toLowerCase()) >= 0
                         }
                         getPopupContainer={(trigger) =>
                           trigger.parentElement
                         }
                       >
                         {medicialGrading.map((item, index) => (
                           <Option key={index} value={item.code}>
                             {item.name}
                           </Option>
                         ))}
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Xray Issue Date")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item name="xray_issue_date">
                       <DatePicker disabled format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                   {canUpdate && (
                     <Form.Item
                       name="xray_issue_date"
                       className="profile-form"
                     >
                       <DatePicker format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Xray Expiry Date")}:</span>
                 <div className="w40-40">
                   {!canUpdate && (
                     <Form.Item name="xray_expiry_date">
                       <DatePicker disabled format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                   {canUpdate && (
                     <Form.Item
                       name="xray_expiry_date"
                       className="profile-form"
                     >
                       <DatePicker format={"DD/MM/YYYY"} />
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Xray Grading")}:</span>
                 <div className="w40-40">
                   {!canUpdate &&
                     clientProfile &&
                     clientProfile.medicals &&
                     clientProfile.medicals.map((item, index) => {
                       return <span key={index}>{item.xrayGrading}</span>;
                     })}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="xray_grading"
                       rules={[
                         {
                           required: false,
                           message: "Please input your xray grading!",
                         },
                       ]}
                     >
                       <Select
                         placeholder="Select"
                         style={{ width: "100%" }}
                         showSearch
                         filterOption={(input, option) =>
                           option.children
                             .toLowerCase()
                             .indexOf(input.toLowerCase()) >= 0
                         }
                         getPopupContainer={(trigger) =>
                           trigger.parentElement
                         }
                       >
                         {medicialGrading.map((item, index) => (
                           <Option key={index} value={item.code}>
                             {item.name}
                           </Option>
                         ))}
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">{t("Medical Notes")}:</span>
                 <div className="w40-40">
                   {!canUpdate &&
                     clientProfile &&
                     clientProfile.medicals &&
                     clientProfile.medicals.map((item, index) => {
                       return <span key={index}>{item.medicalNotes}</span>;
                     })}
                   {canUpdate && (
                     <Form.Item
                       className="profile-form"
                       name="medical_notes"
                       rules={[
                         {
                           required: false,
                           message: "Please input your medical notes!",
                         },
                       ]}
                     >
                       <Select
                         placeholder="Select"
                         style={{ width: "100%" }}
                         showSearch
                         filterOption={(input, option) =>
                           option.children
                             .toLowerCase()
                             .indexOf(input.toLowerCase()) >= 0
                         }
                         getPopupContainer={(trigger) =>
                           trigger.parentElement
                         }
                       >
                         {medicalNotes.map((item, index) => (
                           <Option key={index} value={item.code}>
                             {item.name}
                           </Option>
                         ))}
                       </Select>
                     </Form.Item>
                   )}
                 </div>
               </li>
               <li className="d-flex client-information-setting">
                 <span className="w60-per">
                   {t("Medical Notes Detail")}:
                 </span>
                 <div className="w40-40">
                   {!canUpdate &&
                     clientProfile &&
                     clientProfile.medicals &&
                     clientProfile.medicals.map((item, index) => {
                       return (
                         <span
                           dangerouslySetInnerHTML={{
                             __html: item.medicalNotesDetail,
                           }}
                           key={index}
                         ></span>
                       );
                     })}
                   {canUpdate && (
                     <Form.Item
                       className="last-text-area"
                       name="medical_notes_detail"
                     >
                       <div className="editor-wrapper">
                         <BraftEditor
                           value={editorState}
                           onChange={handleChange}
                         />
                       </div>
                       {/* <Input.TextArea /> */}
                     </Form.Item>
                   )}
                 </div>
               </li>
             </ul>
           </div>
         </div>
       </div>
           </>
            ) : (
              //true
            <></>
            )
    }

        
          
         

          {/* MEDICAL DETAIL */}
      

          <div className="mar-top d-flex flex-end submit-access-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button mr-24"
              disabled={!canUpdate}
            >
              {t("Submit")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default PersonalInformation;
