// LOGIN

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const CLIENT_PROFILE_SUCCESS = "CLIENT_PROFILE_SUCCESS";
export const CLIENT_PROFILE_FAILURE = "CLIENT_PROFILE_FAILURE";

export const GET_CUSTOM_FIELD_SUCCESS = "GET_CUSTOM_FIELD_SUCCESS";
export const GET_CUSTOM_FIELD_FAILURE = "GET_CUSTOM_FIELD_FAILURE";

export const GET_PARTNER_DETAIL_SUCCESS = "GET_PARTNER_DETAIL_SUCCESS";
export const GET_PARTNER_DETAIL_FAILURE = "GET_PARTNER_DETAIL_FAILURE";

export const GET_FAMILY_MEMBER_SUCCESS = "GET_FAMILY_MEMBER_SUCCESS";
export const GET_FAMILY_MEMBER_FAILURE = "GET_FAMILY_MEMBER_FAILURE";

//GET CLIENT MEMBER
export const POST_CLIENT_MEMBER_SUCCESS = "POST_CLIENT_MEMBER_SUCCESS";
export const POST_CLIENT_MEMBER_FAILURE = "POST_CLIENT_MEMBER_FAILURE";

//LOGOUT
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

//PUT API CLEINT PROFILE
export const PUT_CLIENT_PROFILE_SUCCESS = "PUT_CLIENT_PROFILE_SUCCESS";
export const PUT_CLIENT_PROFILE_FAILURE = "PUT_CLIENT_PROFILE_FAILURE";

//POST API EMAIL
export const POST_CLIENT_EMAIL_SUCCESS = "POST_CLIENT_EMAIL_SUCCESS";
export const POST_CLIENT_EMAIL_FAILURE = "POST_CLIENT_EMAIL_FAILURE";

//POST API ADDRESS
export const POST_CLIENT_ADDRESS_SUCCESS = "POST_CLIENT_ADDRESS_SUCCESS";
export const POST_CLIENT_ADDRESS_FAILURE = "POST_CLIENT_ADDRESS_FAILURE";

//POST API PHONE
export const POST_CLIENT_PHONE_SUCCESS = "POST_CLIENT_PHONE_SUCCESS";
export const POST_CLIENT_PHONE_FAILURE = "POST_CLIENT_PHONE_FAILURE";

//POST API PASSPORT
export const POST_CLIENT_PASSPORT_SUCCESS = "POST_CLIENT_PASSPORT_SUCCESS";
export const POST_CLIENT_PASSPORT_FAILURE = "POST_CLIENT_PASSPORT_FAILURE";

//POST API MEDICAL
export const POST_CLIENT_MEDICAL_SUCCESS = "POST_CLIENT_MEDICAL_SUCCESS";
export const POST_CLIENT_MEDICAL_FAILURE = "POST_CLIENT_MEDICAL_FAILURE";

//UPDATE CLIENT MEMBER
export const PUT_CLIENT_MEMBER_SUCCESS = "PUT_CLIENT_MEMBER_SUCCESS";
export const PUT_CLIENT_MEMBER_FAILURE = "PUT_CLIENT_MEMBER_FAILURE";

//GET EMPLOYRE INFORMATION
export const GET_EMPLOYRE_INFORMATION_SUCCESS =
  "GET_EMPLOYRE_INFORMATION_SUCCESS";
export const GET_EMPLOYRE_INFORMATION_FAILURE =
  "GET_EMPLOYRE_INFORMATION_FAILURE";

//POST EMPLOYRE INFORMATION
export const POST_EMPLOYRE_INFORMATION_SUCCESS =
  "POST_EMPLOYRE_INFORMATION_SUCCESS";
export const POST_EMPLOYRE_INFORMATION_FAILURE =
  "POST_EMPLOYRE_INFORMATION_FAILURE";

//PUT EMPLOYRE INFORMATION
export const PUT_EMPLOYRE_INFORMATION_SUCCESS =
  "PUT_EMPLOYRE_INFORMATION_SUCCESS";
export const PUT_EMPLOYRE_INFORMATION_FAILURE =
  "PUT_EMPLOYRE_INFORMATION_FAILURE";

//POST JOB HISTORY
export const POST_JOB_HISTORY_SUCCESS = "POST_JOB_HISTORY_SUCCESS";
export const POST_JOB_HISTORY_FAILURE = "POST_JOB_HISTORY_FAILURE";

//POST QUALIFICATION
export const POST_QUALIFICATION_SUCCESS = "POST_QUALIFICATION_SUCCESS";
export const POST_QUALIFICATION_FAILURE = "POST_QUALIFICATION_FAILURE";

//GET VISA STATUS
export const GET_VISA_STATUS_SUCCESS = "GET_VISA_STATUS_SUCCESS";
export const GET_VISA_STATUS_FAILURE = "GET_VISA_STATUS_FAILURE";

//GET ALL VISA STATUS
export const GET_ALL_VISA_STATUS_SUCCESS = "GET_ALL_VISA_STATUS_SUCCESS";
export const GET_ALL_VISA_STATUS_FAILURE = "GET_ALL_VISA_STATUS_FAILURE";

//GET ALL JOB HISTORY
export const GET_JOB_HISTORY_SUCCESS = "GET_JOB_HISTORY_SUCCESS";
export const GET_JOB_HISTORY_FAILURE = "GET_JOB_HISTORY_FAILURE";

//PUT JOB HISTORY
export const PUT_JOB_HISTORY_SUCCESS = "PUT_JOB_HISTORY_SUCCESS";
export const PUT_JOB_HISTORY_FAILURE = "PUT_JOB_HISTORY_FAILURE";

//GET EDUCATION HISTORY
export const GET_EDUCATION_HISTORY_SUCCESS = "GET_JOB_HISTORY_SUCCESS";
export const GET_EDUCATION_HISTORY_FAILURE = "GET_JOB_HISTORY_FAILURE";

//GET QUALIFICATION
export const GET_QUALIFICATION_SUCCESS = "GET_QUALIFICATION_SUCCESS";
export const GET_QUALIFICATION_FAILURE = "GET_QUALIFICATION_FAILURE";

//PUT QUALIFICATION
export const PUT_QUALIFICATION_SUCCESS = "PUT_QUALIFICATION_SUCCESS";
export const PUT_QUALIFICATION_FAILURE = "PUT_QUALIFICATION_FAILURE";

//GET COUNTRIES
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

//GET PARTNER JOB
export const GET_PARTNER_JOB_SUCCESS = "GET_PARTNER_JOB_SUCCESS";
export const GET_PARTNER_JOB_FAILURE = "GET_PARTNER_JOB_FAILURE";

//GET PARTNER QUALIFICATION
export const GET_PARTNER_QUALIFICATION_SUCCESS =
  "GET_PARTNER_QUALIFICATION_SUCCESS";
export const GET_PARTNER_QUALIFICATION_FAILURE =
  "GET_PARTNER_QUALIFICATION_FAILURE";

//GET  DOCUMENT
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";

//POST  DOCUMENT
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAILURE = "POST_DOCUMENT_FAILURE";

//POST  DOCUMENT
export const POST_DOCUMENT_CHECKLIST_SUCCESS =
  "POST_DOCUMENT_CHECKLIST_SUCCESS";
export const POST_DOCUMENT_CHECKLIST_FAILURE =
  "POST_DOCUMENT_CHECKLIST_FAILURE";

//GET  DOCUMENT
export const GET_BALANCE_HISTORY_SUCCESS = "GET_BALANCE_HISTORY_SUCCESS";
export const GET_BALANCE_HISTORY_FAILURE = "GET_BALANCE_HISTORY_FAILURE";

//GET  VISA FORM
export const GET_VISA_FORM_SUCCESS = "GET_VISA_FORM_SUCCESS";
export const GET_VISA_FORM_FAILURE = "GET_VISA_FORM_FAILURE";

//GET  DOCUMENT CHECK LIST
export const GET_DOCUMENT_CHECKLIST_SUCCESS = "GET_DOCUMENT_CHECKLIST_SUCCESS";
export const GET_DOCUMENT_CHECKLIST_FAILURE = "GET_DOCUMENT_CHECKLIST_FAILURE";

//GET  DOCUMENT DOWNLOAD
export const GET_DOCUMENT_DOWNLOAD_SUCCESS = "GET_DOCUMENT_DOWNLOAD_SUCCESS";
export const GET_DOCUMENT_DOWNLOAD_FAILURE = "GET_DOCUMENT_DOWNLOAD_FAILURE";

//GET  DOCUMENT LINK
export const GET_DOCUMENT_LINK_SUCCESS = "GET_DOCUMENT_LINK_SUCCESS";
export const GET_DOCUMENT_LINK_FAILURE = "GET_DOCUMENT_LINK_FAILURE";

//PUT  DOCUMENT ITEM
export const PUT_DOCUMENT_LINK_SUCCESS = "PUT_DOCUMENT_LINK_SUCCESS";
export const PUT_DOCUMENT_LINK_FAILURE = "PUT_DOCUMENT_LINK_FAILURE";

//GET  EMAIL
export const GET_EMAIL_QUEUE_SUCCESS = "GET_EMAIL_QUEUE_SUCCESS";
export const GET_EMAIL_QUEUE_FAILURE = "GET_EMAIL_QUEUE_FAILURE";

//GET  EMAIL BY SENDER
export const GET_EMAIL_BY_SENDER_SUCCESS = "GET_EMAIL_BY_SENDER_SUCCESS";
export const GET_EMAIL_BY_SENDER_FAILURE = "GET_EMAIL_BY_SENDER_FAILURE";

//GET  EMAIL BY SENDER
export const GET_EMAIL_SENDER_SUCCESS = "GET_EMAIL_SENDER_SUCCESS";
export const GET_EMAIL_SENDER_FAILURE = "GET_EMAIL_SENDER_FAILURE";

//POST  EMAIL BY SENDER
export const POST_EMAIL_SENDER_SUCCESS = "POST_EMAIL_SENDER_SUCCESS";
export const POST_EMAIL_SENDER_FAILURE = "POST_EMAIL_SENDER_FAILURE";

//GET GET CLIENT EMAIL HISTORY
export const GET_CLIENT_EMAIL_HISTORY_SUCCESS =
  "GET_CLIENT_EMAIL_HISTORY_SUCCESS";
export const GET_CLIENT_EMAIL_HISTORY_FAILURE =
  "GET_CLIENT_EMAIL_HISTORY_FAILURE";

export const VISA_TYPE_SUCCESS = "VISA_TYPE_SUCCESS";
export const VISA_TYPE_FAILURE = "VISA_TYPE_FAILURE";

export const GET_VISA_APPLICATION_SUCCESS = "GET_VISA_APPLICATION_SUCCESS";
export const GET_VISA_APPLICATION_FAILURE = "GET_VISA_APPLICATION_FAILURE";

export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILURE = "REFRESH_FAILURE";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS";
export const UPDATE_PERMISSIONS_FAILURE = "UPDATE_PERMISSIONS_FAILURE";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_LINK_INFO_SUCCESS = "GET_LINK_INFO_SUCCESS";
export const GET_LINK_INFO_FAILURE = "GET_LINK_INFO_FAILURE";

export const GET_QUESTIONNAIRE_LINKS_SUCCESS =
  "GET_QUESTIONNAIRE_LINKS_SUCCESS";
export const GET_QUESTIONNAIRE_LINKS_FAILURE =
  "GET_QUESTIONNAIRE_LINKS_FAILURE";

export const GET_JOB_STATUSES_SUCCESS = "GET_JOB_STATUSES_SUCCESS";
export const GET_JOB_STATUSES_FAILURE = "GET_JOB_STATUSES_FAILURE";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const GET_BRANCH_INFO_SUCCESS = "GET_BRANCH_INFO_SUCCESS";
export const GET_BRANCH_INFO_FAILURE = "GET_BRANCH_INFO_FAILURE";
